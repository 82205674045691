import React from "react"
import { graphql, Link } from "gatsby"
import {
  ListingLayoutSingleColumn,
  ListingLayoutGalleryContact,
} from "@organic-return/foundation-react/src/components/Listing"
import MainPage from "@organic-return/foundation-gatsby-plugin-agility/src//MainPage"
import { useSiteContext } from "@organic-return/foundation-gatsby-plugin-agility/src/components/common/SiteContext"
import axios from "axios"
import { getRecaptchaToken } from "@organic-return/foundation-gatsby-plugin-agility/src/lib/utils"
import truncate from "truncate-html"

export const query = graphql`
  query($nodeID: String!) {
    partnerListing(id: { eq: $nodeID }) {
      id
      baths
      address {
        city
        county
        postal
        text
        street1
        street2
      }
      beds
      details {
        name
        details {
          label
          value
        }
      }
      lotacres
      majorArea
      minorArea
      mlsID
      mlsSourceName
      mlsSourceLogoUrl
      price
      sqft
      status
      statusTS
      subType
      subdivision
      type
      exclusive
      flags
      features
      defaultImage {
        dimensions
        url
      }
      path
      description
      descriptionHTML
      images {
        url
        dimensions
        caption
      }
      tours {
        url
        dimensions
        caption
      }
      videos {
        url
        dimensions
        caption
      }
      floorplans {
        url
        dimensions
        caption
      }
      location {
        lat
        lng
      }
      office{
        mlsID
        fullName
        photos{
          url
        }
        address {
          city
          county
          postal
          text
          street1
          street2
          state
        }
      }
      officeName
      agent {
        mlsID
        first
        last
        fullName
        email
        company
        category
        officeName
        phoneOffice
        photos {
          url
        }
        social {
          channel
          url
        }
        path
        phone
        phoneMobile
      }
      similar {
        id
        baths
        address {
          city
          county
          postal
          text
          street1
          street2
        }
        beds
        lotacres
        majorArea
        minorArea
        mlsID
        price
        sqft
        status
        statusTS
        subType
        subdivision
        type
        exclusive
        flags
        defaultImage {
          dimensions
          url
        }
        path
        location {
          lat
          lng
        }
        hideOnMap    
      }
      propertyDocuments {
        title
        file {
          url
        }
      }
      openHouse {
        startDate
        endDate
        headLine
      }
    }
  }
`

const PartnerListingPage = ({ pageContext, data, location }: any) => {
  const siteContext = useSiteContext();

  const Layout = data.partnerListing.exclusive
    ? ListingLayoutSingleColumn
    : ListingLayoutGalleryContact
  let layoutProps = data.partnerListing.exclusive
    ? siteContext.exclusiveLayoutProps
    : siteContext.listingLayoutProps

  layoutProps!.formProps!.clickHandler = async formInput => {
    const recaptchaToken = await getRecaptchaToken("request_information_listing");
    await axios({
      url: process.env.LEAD_CREATE_API_ENDPOINT,
      method: "POST",
      data: {
        recaptchaToken,
        formValues: formInput,
        propertyInfo: {
          createdFrom: `Request Information Listing Page Form`,
          listingId: data.partnerListing.mlsID,
          listingAddress: data.partnerListing.address.text,
          listingPrice: data.partnerListing.price,
          listingAgentMlsId: data.partnerListing.agent && data.partnerListing.agent[0] ? data.partnerListing.agent[0].mlsID : null,
          listingType: data.partnerListing.type
        },
      },
    })
  };

  layoutProps!.contactUsFormProps!.clickHandler = async formInput => {
    const recaptchaToken = await getRecaptchaToken("contact_us_listing");
    await axios({
      url: process.env.LEAD_CREATE_API_ENDPOINT,
      method: "POST",
      data: {
        recaptchaToken,
        formValues: formInput,
        propertyInfo: {
          createdFrom: `Contact US Listing Page Form`,
          listingId: data.partnerListing.mlsID,
          listingAddress: data.partnerListing.address.text,
          listingPrice: data.partnerListing.price,
          listingAgentMlsId: data.partnerListing.agent && data.partnerListing.agent[0] ? data.partnerListing.agent[0].mlsID : null,
          listingType: data.partnerListing.type
        },
      },
    })
  };

  data.partnerListing.similar?.forEach(similar => {
    !similar.defaultImage?.dimensions &&
      similar.defaultImage &&
      (similar.defaultImage.dimensions = [800, 600])
  })

  data.partnerListing.office = data.partnerListing.office ?? [];
  data.partnerListing.agent = data.partnerListing.agent ?? [];

  var pageTitle = `${data.partnerListing.address?.text} #${data.partnerListing.mlsID} - Real Estate`;
  var pageDescription = data.partnerListing.description ? truncate(data.partnerListing.description, 297) : "";

  return (
    <MainPage
      seoProps={{
        title: pageTitle,
        description: pageDescription,
        ogImage: data.partnerListing.defaultImage?.url,
        ogType: "place",
        location: location
      }}
      headerProps={{ ...siteContext.headerProps, gradient: data.partnerListing.exclusive }}
      className={`listing-page ${data.partnerListing.exclusive ? "listing-exclusive" : ""
        }`}
    >
      <Layout
        className={`mx-auto ${!data.partnerListing.exclusive && "md:mt-28"}`}
        listing={data.partnerListing}
        agents={data.partnerListing.agent}
        similar={data.partnerListing.similar}
        title={data.partnerListing.address.text}
        linkFunc={props => {
          return (
            <Link
              to={props.href}
              className={`hide-disabled text-inherit ${props.className}`}
              key={props.key}
              title={props.title}
            >
              {props.children}
            </Link>
          )
        }}
        {...layoutProps}
      />
    </MainPage>
  )
}

export default PartnerListingPage
